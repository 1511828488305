/* latin-ext */
@font-face {
  font-family: '__Quando_59535c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/73df44cc095237c8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Quando_59535c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/32f21afdec8be873-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Quando_Fallback_59535c';src: local("Times New Roman");ascent-override: 73.07%;descent-override: 19.08%;line-gap-override: 0.00%;size-adjust: 135.65%
}.__className_59535c {font-family: '__Quando_59535c', '__Quando_Fallback_59535c';font-weight: 400;font-style: normal
}

